<template>
    <div class="products">
      <div class="page-header">
        <h1>{{ $t('Delivery Management')}} > {{ $t('Customers') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Customers') }}</h2>
        </div>
        <ItemFilter :placeholder="$t('Search by member name, email, phone')" default="memberName" :selection="true">
          <el-option :label="$t('Cusomter Name Search')" value="memberName"></el-option>
          <el-option :label="$t('Email Search')" value="Email Search"></el-option>
          <el-option :label="$t('Phone Search')" value="Email Search"></el-option>
        </ItemFilter>
  
        <div class="inventory-list">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t('Name') }}</th>
                <th scope="col">{{ $t('Email') }}</th>
                <th scope="col">{{ $t('Phone Number')}}</th>
                <th scope="col">{{ $t('Adress')}}</th>
                <th scope="col">{{ $t('Actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>傅舒揚</td>
                <td>3IFEXPZGD@gmail.com</td>
                <td>98063418</td>
                <td>葵芳金龍工業中心五樓502室</td>
                <td>
                  <div class="action-links">
                    <a class="action-link" href="#">
                      {{ $t('View Order') }}
                    </a>
                    <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>連睎雅</td>
                <td>clo@gmail.com</td>
                <td>63982014</td>
                <td>香港元朗區新界元朗鳳琴街611號</td>
                <td>
                  <div class="action-links">
                    <a class="action-link" href="#">
                      {{ $t('View Order') }}
                    </a>
                    <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>葛世軒</td>
                <td>4iwa2lc@gmail.com</td>
                <td>51578604</td>
                <td>香港灣仔區雲地利道417號</td>
                <td>
                  <div class="action-links">
                    <a class="action-link" href="#">
                      {{ $t('View Order') }}
                    </a>
                    <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>孫冠達</td>
                <td>不適用</td>
                <td>59304742</td>
                <td>香港元朗區丹桂村路368號</td>
                <td>
                  <div class="action-links">
                    <a class="action-link" href="#">
                      {{ $t('View Order') }}
                    </a>
                    <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>盧智晴</td>
                <td>不適用</td>
                <td>58892236</td>
                <td>香港九龍城區樂善道727號</td>
                <td>
                  <div class="action-links">
                    <a class="action-link" href="#">
                      {{ $t('View Order') }}
                    </a>
                    <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>向希廷</td>
                <td>不適用</td>
                <td>62805216</td>
                <td>香港中西區香港灣仔天寶街304號</td>
                <td>
                  <div class="action-links">
                    <a class="action-link" href="#">
                      {{ $t('View Order') }}
                    </a>
                    <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>熊澤峯</td>
                <td>不適用</td>
                <td>55967208</td>
                <td>香港屯門區三聖街394號</td>
                <td>
                  <div class="action-links">
                    <a class="action-link" href="#">
                      {{ $t('View Order') }}
                    </a>
                    <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>羅國燁</td>
                <td>不適用</td>
                <td>61199853</td>
                <td>香港九龍城區大環馬頭圍道437號</td>
                <td>
                  <div class="action-links">
                    <a class="action-link" href="#">
                      {{ $t('View Order') }}
                    </a>
                    <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>洪筱婷</td>
                <td>不適用</td>
                <td>96913682</td>
                <td>葵芳金龍工業中心三樓302室</td>
                <td>
                  <div class="action-links">
                    <a class="action-link" href="#">
                      {{ $t('View Order') }}
                    </a>
                    <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import ItemFilter from '@/components/Filter.vue';
  import Common from '@/lib/common';
  import Member from '@/lib/member';
  
  export default {
    name: 'DeilveryCustomers',
    components: {
      ItemFilter,
    },
    mounted(){
      //this.loadAllMembers();
    },
    methods:{
      async loadAllMembers(){
        try{
          const loginInfo = Common.getLoginInfo();
          const members = await Member.loadAllMembers(this.apiUrl, loginInfo);
          this.members = members;
        }catch(err){
          console.log(err);
        }
      },
    },
    data(){
      return {
        members: [],
        orignalMembers: [],
      }
    },
    computed: mapState({
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  </style>
  